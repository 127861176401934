import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';

export default class GeneralField extends Component{

	handleOnChange = (fieldName, value) => {
		const prefix = this.props.fieldData.prefix;
		if(fieldName === "iban" || fieldName === "email") value = value.replace(/\s/g, "");
		if(prefix && value.indexOf(prefix) !== 0) value = prefix;

		if(fieldName !== "name" || !/\d/.test(value))
			this.props.setValue(fieldName, value);
	}

	render(){
		const {fieldData, submitData, inputWrapperStyle, hasError, errorMessage, validateField, readOnly} = this.props;
		const auxFieldValue = (fieldData.name === "postalCode" || fieldData.name === "iban") ? (submitData.formValues.country || 'Portugal') : '';

		const maxLength = fieldData.length || (fieldData.name === "iban" && auxFieldValue === 'Portugal' ? 25 : fieldData.name === "postalCode" && auxFieldValue === 'Portugal' ? 8 : undefined);

		return (
			<TextField 
				id={fieldData.name}
				error={hasError}
				style={ inputWrapperStyle(fieldData.width) }
				className={(submitData.formValues[fieldData.name]) ? "input-wrapper filled" : "input-wrapper"}
				value={submitData.formValues[fieldData.name] || ""}
				name={fieldData.name}
				type={fieldData.type || "text"}
				label={fieldData.label + (fieldData.required ? " *":"")}
				helperText={errorMessage}
				onChange={(e) => this.handleOnChange(fieldData.name, e.target.value)}
				onBlur={(e) => !readOnly && validateField(fieldData.name, e.target.value, fieldData.required, fieldData.validations, auxFieldValue)}
				fullWidth={true}
				variant="outlined"
				InputProps={{
					readOnly:readOnly
				}}
				inputProps={{
					maxLength : maxLength
				}}
				multiline={fieldData.multiline}
				rows={"3"}
			/>
		)
	}
}
