import React, { Component } from 'react';
import Amount from './Amount';
import Birthdate from './Birthdate';
import CustomCheckbox from './CustomCheckbox';
import FieldWithCountry from './FieldWithCountry'
import GeneralField from './GeneralField';
import Picklist from './Picklist';
import Signature from './Signature';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
const labels = require("../../labels.js");

export default class Section extends Component {
	state = {
		showFields : true
	};

	simpleAmountComponent = (submitData, fieldData, hasError, errorMessage, setValue, validateField, readOnly) => {
		const value = submitData.formValues[fieldData.name];

		return (
			<TextField 
				key="amount" 
				type="number"
				error={hasError}
				className={value ? "input-wrapper filled" : "input-wrapper"}
				value={value || ""}
				name={fieldData.name}
				multiline={false}
				helperText={errorMessage}
				onChange={(e) => setValue("amount", e.target.value)}
				onBlur={(e) => validateField(fieldData.name, e.target.value, fieldData.required, fieldData.validations, null)}
				fullWidth={true}
				variant="outlined"
				InputProps={{
					endAdornment: (
						<InputAdornment variant="outlined" position="end" className="input-adornment">
							€
						</InputAdornment>
					),
					readOnly:readOnly
				}}
			/>
		);
	}
	
	inputWrapperStyle = (fieldWidth, fieldType) => {
		const style = {
			margin: (fieldType === "checkbox") ? "0" : "20px 0 0",
			height: "auto",
			width: "100%"
		};

		if(fieldWidth !== 100){
			return{
				...style,
				width: fieldWidth-2+"%",
				display: "inline-flex",
			}
		}else{
			return style;
		}
	}

	checkFieldDependencies = (submitData, fieldDependencies) => {
		const found = fieldDependencies.some(
			v => submitData.formValues[v]
		);

		return found;
	}

	getSectionComponents = (field, props) => {
		const showFields = !field.dependencies || (field.dependencies && this.checkFieldDependencies(props.submitData, field.dependencies));
		let fieldComponents;
		let auxFieldComponents;

		if(showFields){
			const { readOnly, setValue, validateField, language, themeColors, formErrors, submitData, toggleModal, isModalOpen, fixedAmountSelected, openAmountSelected, simpleAmountStyle, step, fieldLogic } = props;

			const relatedFieldLogic = fieldLogic && fieldLogic[step-1] && fieldLogic[step-1].filter(fName => fName.indexOf(field.name) !== -1);
			const avoidIncomplete = relatedFieldLogic && relatedFieldLogic.length > 0 && !(relatedFieldLogic[0] instanceof Array) && relatedFieldLogic[0].charAt(0) === "#";

			const hasError = formErrors && formErrors[field.name] && !formErrors[field.name].valid;
			const errorMessage = formErrors && formErrors[field.name] && !formErrors[field.name].valid && formErrors[field.name].errorMessage;
			const fieldValue = submitData.formValues[field.name];

			if(field.type === "amount"){
				if(simpleAmountStyle){
					auxFieldComponents = this.simpleAmountComponent(submitData, field, hasError, errorMessage, setValue, validateField, readOnly);
				}else{
					auxFieldComponents = (
						<Amount 
							key={field.name}
							fieldData={field}
							hasError={hasError}
							errorMessage={errorMessage}
							inputWrapperStyle={this.inputWrapperStyle}
							setValue={setValue}
							validateField={validateField}
							themeColors={themeColors}
							fixedAmountSelected={fixedAmountSelected}
							openAmountSelected={openAmountSelected}
							readOnly={readOnly}
						/>
					)
				}
			}else if(field.type === "fiscalId" || field.type === "phone" || field.type === "citizenCard"){
				auxFieldComponents = (
					<FieldWithCountry 
						key={field.name}	
						fieldData={field}
						submitData={submitData} 
						fieldValue={fieldValue}
						hasError={hasError}
						errorMessage={errorMessage}
						inputWrapperStyle={this.inputWrapperStyle}
						setValue={setValue}
						validateField={validateField}
						readOnly={readOnly}
					/>
				)
			}else if(field.type === "picklist"){
				auxFieldComponents = (
					<Picklist
						key={field.name} 
						fieldData={field}
						fieldValue={fieldValue}
						hasError={hasError}
						errorMessage={errorMessage}
						inputWrapperStyle={this.inputWrapperStyle}
						setValue={setValue}
						validateField={validateField}
						formErrors={formErrors}
						readOnly={readOnly}
					/>
				)
			}else if(field.type === "birthdate"){
				auxFieldComponents = (
					<Birthdate
						key={field.name} 
						fieldData={field}
						hasError={hasError}
						errorMessage={errorMessage}
						inputWrapperStyle={this.inputWrapperStyle}
						setValue={setValue}
						validateField={validateField}
						formErrors={formErrors}
						fieldValue={fieldValue}
						readOnly={readOnly}
						language={language}
					/>
				)
			}else if(field.type === "checkbox"){
				auxFieldComponents = (
					<CustomCheckbox
						key={field.name}
						fieldData={field}
						fieldValue={fieldValue} 
						hasError={hasError}
						errorMessage={errorMessage}
						inputWrapperStyle={this.inputWrapperStyle}
						setValue={setValue}
						toggleModal={toggleModal}
						isModalOpen={isModalOpen}
						readOnly={readOnly}
						language={language}
						validateField={validateField}
					/>
				)
			}else if(field.type === "signature"){
				auxFieldComponents = (
					<Signature
						key={field.name}	 
						setValue={setValue}
						hasError={hasError}
						errorMessage={errorMessage}
						readOnly={readOnly}
						existingSignature={readOnly && submitData.formValues.signature}
						language={language}
					/>
				)
			}else{
				auxFieldComponents = (
					<GeneralField
						key={field.name}
						fieldData={field}
						submitData={submitData} 
						hasError={hasError}
						errorMessage={errorMessage}
						inputWrapperStyle={this.inputWrapperStyle}
						setValue={setValue}
						validateField={validateField}
						readOnly={readOnly}
					/>
				)
			}

			if(avoidIncomplete){
				field.width = "102";
				const auxField = {
					name : "avoid"+field.name,
					label : labels.getLabel("avoidIncomplete", language, "", field.label),
					required : false,
					type : "checkbox"
				}
				fieldComponents = (
					// o 50 que entra em baixo na funÃ§Ã£o do estilo nÃ£o pode ser estÃ¡tico. Para jÃ¡ fica assim por questÃµes de tempo
					<div style={{ flexDirection : "column", ...this.inputWrapperStyle("50", "checkbox")}}> 
						{auxFieldComponents}
						<CustomCheckbox
							key={auxField.name}
							fieldData={auxField}
							fieldValue={submitData.formValues[auxField.name]} 
							hasError={formErrors && formErrors[auxField.name] && !formErrors[auxField.name].valid}
							errorMessage={formErrors && formErrors[auxField.name] && !formErrors[auxField.name].valid && formErrors[auxField.name].errorMessage}
							inputWrapperStyle={this.inputWrapperStyle}
							setValue={setValue}
							toggleModal={toggleModal}
							isModalOpen={isModalOpen}
							readOnly={readOnly}
							language={language}
							validateField={validateField}
						/>
					</div>
				)
			}else{
				fieldComponents = auxFieldComponents;
			}
		}

		return fieldComponents;
  };

	render(){
		const { sectionData, isMobile } = this.props;
		
		let fieldsToShow = sectionData.fields.map(field => 
			this.getSectionComponents(field, this.props)
		).filter(e => e !== null);
		
		if( fieldsToShow.length > 0 )
			return(
				<div className={isMobile ? "form-section mobile" : "form-section"} >	
					<Typography className="section-title" variant="subtitle1">{sectionData.label}</Typography>
					{fieldsToShow}
				</div>
			);
		else
			return null;
	}
}