import React, { Component } from 'react';

class LoadingSpinner extends Component {
    render(){    
        const { text } = this.props;

        return(
            <div className="loader-wrapper">
                <div className="loader" />
                {text &&
                    <div className="text">
                        {text}
                    </div>
                }
            </div>
        )
    }
}
export default LoadingSpinner;