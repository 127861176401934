import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';
import UserICon from '@material-ui/icons/AccountCircle';

export default class UserImage extends Component {
    constructor(props){
        super(props);
        this.state = {
            image : props.userImage
        }
    }

    handleImageError = () => this.setState({ image : "" });

    componentWillUpdate(nextProps){
        if(nextProps.userImage !== this.props.userImage){
            this.setState({
                image : nextProps.userImage
            });
        }
    }

    render(){
        const { image } = this.state;
        const { userImage } = this.props;

        let currentImage = (image !== undefined) ? image : userImage;

        //console.log(image)
        //console.log(userImage)

        return(
            <Avatar
                alt="user image"
                src={currentImage}
                className="avatar"
                imgProps={{
                    onError: this.handleImageError
                }}
            >
                <UserICon className="default-image"/>
            </Avatar>
        )
    }
}