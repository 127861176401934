import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import InputBase from '@material-ui/core/InputBase';
import MenuIcon from '@material-ui/icons/Menu';
import SyncIcon from '@material-ui/icons/Sync';
//import NotificationsIcon from '@material-ui/icons/Notifications';
import SearchIcon from '@material-ui/icons/Search';
/*import StopIcon from '@material-ui/icons/Stop';
import PlayIcon from '@material-ui/icons/PlayArrow';*/
import { withStyles } from '@material-ui/core/styles';
import SearchSuggestions from './SearchSuggestions.js';
const utilityFunctions = require("../utility_functions");
const labels = require("../labels");
//const uuidv4 = require('uuid/v4');

const styles = theme => ({
    badge: {
      border: `2px solid white`,
    }
});

class TopMenu extends Component {
    searchNode = React.createRef();
    state = {
        activeSearch : false,
        searchValue : "",
        searchSuggestions : [],
        isTimerActive : false,
        openTimes : []
    }

    /*componentWillMount(){
        const { times, userData } = this.props;

        if(userData.timerOptional){
            if(times && times.length > 0 && !times[0].endTime)
                this.setState({ isTimerActive : true });

            if(navigator.onLine){
                const filters = "?filter[username][eq]="+this.props.userData.username+"&filter[endTime][is]=";
                utilityFunctions.getTimesList(filters)
                .then(openTimes => {
                    this.setOpenTimes(openTimes);
                });
            }else{
                this.setOpenTimes([]);
            }
        }
    }

    componentWillReceiveProps(nextProps){
        if(!nextProps.userData.timerOptional && nextProps.times && nextProps.times.length > 0 && !nextProps.times[0].endTime)
            this.setState({ isTimerActive : true });
    }

    setOpenTimes = (openTimes) => {
        const { userData, getLocalStorageEntries } = this.props;
        const localStorageTimes = getLocalStorageEntries(userData.orgId, userData.username).timeEntries;
        if(localStorageTimes !== null){
            let openLocalStorageTimes = localStorageTimes.filter(t => !t.endTime);
            let idsInLocalStorage = localStorageTimes.map(t => t.id);
            openTimes = openTimes.filter(t => idsInLocalStorage.indexOf(t.id) === -1 ).concat(openLocalStorageTimes);
            openTimes.sort((a,b) =>
                ( new Date(a.startTime).getTime() > new Date(b.startTime).getTime() ) ? 1 : (( new Date(b.startTime).getTime() > new Date(a.startTime).getTime() ) ? -1 : 0)
            );
        }
        const isTimerActive = openTimes.length > 0 || this.state.isTimerActive;
        this.setState({ openTimes, isTimerActive });
    }

    toggleTimer = () => this.setState({ isTimerActive : !this.state.isTimerActive });

    handleTimer = () => {
        let isPlay = !this.state.isTimerActive;
        let now = new Date().getTime();

        if(this.state.openTimes.length > 0){
            this.handleOpenTimesList(isPlay, now);
        }else{
            this.handleOriginalTimesList(isPlay, now);
        }
    }

    handleOpenTimesList = (isPlay, now) => {
        let lastOpenTime = this.state.openTimes[0];
        lastOpenTime.startTime = new Date(lastOpenTime.startTime).getTime();
        lastOpenTime.endTime = now;

        this.props.addTime(lastOpenTime, isPlay);
        this.setTimes(lastOpenTime, isPlay);
        this.setState({
            openTimes : [],
            isTimerActive : false
        });
    }

    handleOriginalTimesList = (isPlay, now) => {
        let requestObj = {};

        if(isPlay){
            requestObj = {
                username: this.props.userData.username,
                id: uuidv4(),
                startTime: now
            }
        }else{
            let currentTimes = this.props.times;

            requestObj = {
                ...currentTimes[0],
                startTime : new Date(currentTimes[0].startTime).getTime(),
                endTime: now
            }
        }

        this.props.addTime(requestObj, isPlay);
        this.setTimes(requestObj, isPlay);
        this.toggleTimer();
    }

    setTimes = (timeObj, isPlay) => {
        let { times } = this.props;
        if(isPlay){
            times.unshift(timeObj);
        }else{
            times[0] = timeObj;
        }
        this.props.setTimes(times);
    }*/

    toggleSearch = () => 
        this.setState({ 
            searchValue : "",
            searchSuggestions : [],
            activeSearch : !this.state.activeSearch 
        });

    handleSearch = (value) => {
        this.setState({ searchValue : value });
        if(value !== ""){
            utilityFunctions.getSearchResult(value, true)
            .then( suggestions => this.setState({ searchSuggestions : suggestions }) )
        }
    }

    goToSearchSuggestionPage = (suggestion) => 
        this.props.history.push({
            pathname: '/donor-profile',
            search: `?id=${suggestion.id}`
        });

    getLocalStorageFormEntriesLength = (formEntries) => {
        let length = 0;
        Object.keys(formEntries).forEach(formId => formEntries[formId].forEach(entry => length++));
        return length;
    }

    render() {
        const { 
            userData, 
            openMenu, 
            classes, 
            localStorageFormEntries,
            localStorageNoteEntries,
            localStorageTimeEntries,
            isSynching,
            language
        } = this.props;
        const { 
            activeSearch,
            searchValue,
            searchSuggestions,
            //isTimerActive
        } = this.state;

        //console.log(this.props)
        //console.log(this.state)

        const localStorageFormEntriesLength = this.getLocalStorageFormEntriesLength(localStorageFormEntries);
        const localStorageNoteEntriesLength = localStorageNoteEntries.length;
        const localStorageTimeEntriesLength = localStorageTimeEntries.length;
        const synchTotalNumber = localStorageFormEntriesLength + localStorageNoteEntriesLength + localStorageTimeEntriesLength;

        return (
            <div className="top-menu">
                <IconButton
                    onClick={openMenu}
                    color="inherit"
                    aria-label="Open menu"
                >
                    <MenuIcon />
                </IconButton>

                <Typography variant="h6" className="ong-name">{userData.orgName}</Typography>

                <div className="menu-item-wrapper">
                    {userData.profile !== "Recruiter" &&
                        <div 
                            className={activeSearch ? "menu-item search active":"menu-item search"} 
                        >
                            <IconButton aria-label="Search" onClick={this.toggleSearch}>
                                <SearchIcon
                                    className="icon"
                                />
                            </IconButton>
                            {activeSearch &&
                                <div style={{ width:"100%"}}>
                                    <InputBase
                                        placeholder={labels.getLabel("Search", language)}
                                        autoFocus
                                        fullWidth
                                        value={searchValue}
                                        onChange={(e) => this.handleSearch(e.target.value)}
                                        onBlur={() => setTimeout(() => this.toggleSearch(), 250) }
                                        inputRef={node => this.searchNode = node}
                                    />
                                    <SearchSuggestions
                                        open={searchSuggestions.length > 0}
                                        anchorEl={this.searchNode}
                                        onSuggestionClick={this.goToSearchSuggestionPage}
                                        searchSuggestions={searchSuggestions}
                                        marginTop={25}
                                    />
                                </div>
                            }
                        </div>
                    }

                    {/*<div className="menu-item">
                        <IconButton aria-label="Notifications">
                            <Badge 
                                badgeContent={0} 
                                invisible={true}
                                color="primary" 
                                classes={{ badge : classes.badge }}
                            >
                                <NotificationsIcon 
                                    className="icon"
                                />
                            </Badge>
                        </IconButton>
                    </div>*/}

                    <div className="menu-item">
                        <IconButton 
                            aria-label="Synchronize" 
                            className="sync"
                            onClick={synchTotalNumber > 0 ? this.props.synchLocalStorage : null}
                        >
                            <Badge
                                badgeContent={synchTotalNumber} 
                                color="primary" 
                                invisible={!synchTotalNumber > 0}
                                classes={{ badge : classes.badge }}
                            >
                                <SyncIcon
                                    className={isSynching ? "icon synching" : "icon"}
                                />
                            </Badge>
                        </IconButton>
                    </div>

                    {/*!userData.timerOptional &&
                        <div className={"menu-item timer"+(isTimerActive ? " active":"")}>
                            <IconButton 
                                aria-label="Toggle time"
                                onClick={this.handleTimer}
                            >
                                {isTimerActive
                                    ? <StopIcon className="icon" />
                                    : <PlayIcon className="icon"/>
                                }
                            </IconButton>
                        </div>
                    */}
                </div>
            </div>
        );
    }
}
  
export default withStyles(styles)(withRouter(TopMenu));