import React, { Component } from 'react';
import DatePickerCustom from './DatePickerCustom.js';

export default class Birthdate extends Component{
	render(){
		const {fieldData, formErrors, validateField, fieldValue, setValue, inputWrapperStyle, hasError, errorMessage, readOnly, language} = this.props;
		
		return (
			<div style={ inputWrapperStyle(fieldData.width) }>
				<DatePickerCustom
					name={fieldData.name}
					required={fieldData.required}
					validations={fieldData.validations}
					label={fieldData.label}
					hasError={hasError}
					errorMessage={errorMessage}
					onChange={setValue} 
					formErrors={formErrors}
					onBlur={validateField}
					value={fieldValue}
					className={(fieldValue) ? "input-wrapper filled" : "input-wrapper"}
					readOnly={readOnly}
					maxYear={new Date().getFullYear() - 18}
					minYear={new Date().getFullYear() - 100}
					language={language}
				/>
			</div>
		)
	}
}