//https://www.cssscript.com/demo/creating-fast-and-responsive-gauges-with-pure-css/
//https://www.kenflerlage.com/2017/07/creating-nps-gauges-in-tableau.html

import React, { Component } from 'react';

export default class GaugeChart extends Component {
    render(){
        const { label, min, max, delimiters, currentValue, width, bonus } = this.props;

        const measuresPercentage = 100/((width*100)/400);

        const currentPercentage = (currentValue <= max) ? (currentValue/max)*100 : 50;

        let gaugeCStyle = {
            transform : (currentValue <= max) ? `rotate(${((currentValue*0.5)/max)}turn)` : "rotate(0.5turn)",
            top: 200/measuresPercentage
        }

        if(currentPercentage < delimiters[0]){
            gaugeCStyle.backgroundColor = "#d33234";
        }else if(currentPercentage >= delimiters[0] && currentPercentage < delimiters[1]){
            gaugeCStyle.backgroundColor = "#ffc212";
        }else if(currentPercentage >= delimiters[1]){
            gaugeCStyle.backgroundColor = "#59a14f";
        }

        const minStyle = {
            marginLeft: min > 9 ? 25/measuresPercentage : 30/measuresPercentage,
            fontSize: `${1.7/measuresPercentage}em`
        }

        const maxStyle = {
            marginRight: max > 9 ? 25/measuresPercentage : 30/measuresPercentage,
            fontSize: `${1.7/measuresPercentage}em`
        }

        const generalMeasures = {
            width : 400/measuresPercentage,
            height : 200/measuresPercentage,
        }

        const bMeasures = {
            width : 250/measuresPercentage,
            height : 125/measuresPercentage,
            top : 75/measuresPercentage,
            marginLeft : 75/measuresPercentage
        }

        const b1Style = {
            background: `linear-gradient(to right, red 0%, red ${delimiters[0]}%, orange ${delimiters[0]}%, orange ${delimiters[1]}%, green ${delimiters[1]}%, green)`
        }

        const dataMeasures = {
            top : 126/measuresPercentage,
            fontSize : `${1.5/measuresPercentage}em`
        }

        return(
            <div className="gauge-container">
                <label>
                    {label}
                </label>
                <div className="gauge" style={generalMeasures}>
                    <div className="gauge-a" style={generalMeasures}></div>
                    <div className="gauge-b" style={bMeasures}>
                        <div className="gauge-b1" style={b1Style}></div>
                        <div className="gauge-b2"></div>
                    </div>
                    <div className="gauge-c" style={{...generalMeasures, ...gaugeCStyle}}></div>
                    <div className="gauge-data" style={{...dataMeasures, ...generalMeasures}}>
                        <h1 style={{ zIndex : "1" }}>
                            {currentValue}
                        </h1>
                    </div>
                </div>
                <h2 className="min" style={minStyle}>
                    {min}
                </h2>
                <h2 className="max" style={maxStyle}>
                    {max}
                </h2>
                <p style={{ margin:"25px" }}>
                    Bonus: {bonus}€
                </p>
            </div>
        )
    }
}