import React, { Component } from 'react';
import SuccessIcon from '@material-ui/icons/CheckCircleOutline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ErrorIcon from '@material-ui/icons/Error';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";
const ValidateObject = require("../validation-schemas");
const labels = require("../labels");
const host = (process.env.NODE_ENV === "development") ? require("../config.json").developerHost : require("../config.json").productionHost;
const utility_functions = require("../utility_functions.js");
const recaptchaRef = React.createRef();

class ForgotPassword extends Component {
    constructor(){
        super()
        this.state = {
            username : '',
            email : '',
            submitSuccess : false,
            errors : {},
            generalError: ""
        }
    }

    setUsername = (value) => {
        value = value.replace(/\s/g, "");
        this.setState({
            username: value
        })
    }

    validateEmail = (username) => {
        let check = true;

        const validationResult = ValidateObject.resetPassword({username});

        if(validationResult.error){
            check = false;
            this.setState({
                errors : utility_functions.getSchemaErrorMessages(validationResult, this.props.language)
            });
        }

        return check;
    }

    sendResetEmail = (e) => {
        e.preventDefault();

        const {username} = this.state;

        if(this.validateEmail(username)){
            this.props.toggleLoading();
            axios.post(`${host}/users/${username}/reset-password`, {}, {withCredentials:true, headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'}})
            .then(res => {
                if(res.status === 200){
                    const email = res.data.data.email;
                    this.props.toggleLoading();
                    this.setState({
                        email,
                        teste : email,
                        submitSuccess : true   
                    });
                }
            })
            .catch(error => {
                console.log(error.response);
                console.log(error.response.data);
                const { language } = this.props;
                if(error.response && error.response.data && error.response.data.data.shortMessage && error.response.data.data.shortMessage === "NO_USER"){
                    this.props.toggleLoading();
                    this.setState({ 
                        errors: { 
                            username: labels.getLabel(error.response.data.data.shortMessage, language)
                        }  
                    });
                }else if(error.response && error.response.status >= 500){
                    this.setState({ 
                        errors: { 
                            all: labels.getLabel("STATUS_500", language)
                        }
                    });
                }
            })
        }
    }

    render(){
        const { email, username, errors, submitSuccess } = this.state;
        const { language } = this.props;

        return (
            submitSuccess 
                ? <div className="submit-success">
                    <SuccessIcon className="icon success"/>
                    <h1 className="title">{labels.getLabel("passwordResetEmailSent", language)}</h1>
                    <p className="text" dangerouslySetInnerHTML={{__html: labels.getLabel("passwordResetEmailSentTo", language, "", email)}} />
                  </div>
                : <form 
                    className={this.props.className} 
                    onSubmit={(e) => this.sendResetEmail(e)}
                  >
                        <p className="info-text">{labels.getLabel("lostYourPasswordTitle", language)}</p>
                        <div className="fields">
                            <div className="input-wrapper">
                                <TextField 
                                    value={username}
                                    label="Username"
                                    type="email"
                                    multiline={false}
                                    error={errors && errors.username !== undefined}
                                    helperText={errors && errors.username}
                                    onChange={(e) => this.setUsername(e.target.value)}
                                    fullWidth={true}
                                />
                            </div>
                        </div>
                        {errors && errors.all &&
                            <div className="error-message">
                                <ErrorIcon />
                                <span>{errors.all}</span>
                            </div>	
                        }
                        <div className="buttons-wrapper">
                            <Button color="secondary" fullWidth={true} variant="contained" type="submit">Reset Password</Button>
                            <Button className="cancel" onClick={this.props.togglePasswordReset}>{labels.getLabel("cancel", language)}</Button>
                        </div>
                    </form>
        )
    }       
}

class Login extends Component {
    constructor(){
        super()
        this.state = {
            loginData : {},
            errors : {},
            authenticationError: "",
            isReset : false
        }
    }

    handleChange = (name, value) => {
        if(name === "username")
            value = value.replace(/\s/g, "");
        this.setState({
            loginData : {
                ...this.state.loginData,
                [name] : value
            }
        })
    }

    togglePasswordReset = () => this.setState({ isReset: !this.state.isReset });

    handleSubmit = (e) => {
        const { toggleLoading } = this.props;
        toggleLoading();
        e.preventDefault();
        recaptchaRef.current.execute();
    }

    handleRecaptchaCallback = (recaptchaToken) => {
        const { language } = this.props;
        setTimeout(
            () => {
                
                const {loginData} = this.state;
                const validationResult = ValidateObject.userCredentials(loginData);
                if(validationResult.error){
                    this.props.toggleLoading();
                    this.setState({
                        errors : utility_functions.getSchemaErrorMessages(validationResult, language),
                        authenticationError: ""
                    })
                }else{
                    this.setState({ 
                        errors: {},
                    }, this.authenticate({...loginData, recaptchaToken })); 
                }
                recaptchaRef.current.reset();
            }
        , 100);
    }

    authenticate = (loginData) => {
        axios({method:"post", url:`${host}/users/me`, data:loginData, withCredentials:false, headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'}} )
        .then(res => {
            if(res.data.status === "success")
                this.props.login(res.data.data.userData)
        })
        .catch(error => {
            const { language } = this.props;
            this.props.toggleLoading();
            if (error.response && error.response.data.data.shortMessage) {
                this.setState({
                    authenticationError : labels.getLabel(error.response.data.data.shortMessage, language),
                });
            }else if(error.response && error.response.status >= 500){
                this.setState({
                    authenticationError : labels.getLabel("STATUS_500", language), 
                });
            }
        })  
    }
    
    render(){
        const { errors, authenticationError, isReset } = this.state;
        const { language } = this.props;

        const loginStyle = {
            height: 
                (isReset)
                ? "430px"
                : (authenticationError) 
                ? "570px"
                : (errors && Object.keys(errors).length > 0)
                ? "520px"
                : "480px"
        }

        return (
            <div className="login-background">
                <div 
                    className="login-wrapper" 
                    style={loginStyle}
                >

                    <div className="logo" />

                    <ForgotPassword 
                        className={isReset ? "reset-password visible" : "reset-password not-visible"}
                        togglePasswordReset={this.togglePasswordReset}
                        toggleLoading={this.props.toggleLoading}
                        language={language}
                    />

                    <form 
                        className={isReset ? "login-form not-visible" : "login-form"} 
                        onSubmit={this.handleSubmit}
                    >
                        <Typography variant="h5">
                            {labels.getLabel("login", language)}
                        </Typography>
                        <div className="fields">
                            <div className="input-wrapper">
                                <TextField 
                                    label="Username"
                                    error={errors && errors.username !== undefined}
                                    helperText={errors && errors.username}
                                    onChange={(e) => this.handleChange('username', e.target.value)} 
                                    fullWidth={true}
                                />
                            </div>
                            <div className="input-wrapper">
                                <TextField 
                                    label="Password" 
                                    type="password"
                                    multiline={false}
                                    error={errors && errors.password !== undefined}
                                    helperText={errors && errors.password}
                                    onChange={(e) => this.handleChange('password', e.target.value)} 
                                    fullWidth={true}
                                />
                            </div>
                            <div className="grecaptcha-info">
                            This site is protected by reCAPTCHA and the Google&nbsp;
                                <a targer="_blank" style={{textDecoration: 'underline'}} href="https://policies.google.com/privacy">Privacy Policy</a> and&nbsp;
                                <a targer="_blank" style={{textDecoration: 'underline'}} href="https://policies.google.com/terms">Terms of Service</a> apply.
                            </div>
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey="6LfKLNMUAAAAAKrA-Rl53ToCOnynyiyk9E-LvJ_n"
                                onChange={this.handleRecaptchaCallback}
                                size="invisible"
                            />
                        </div>

                        {authenticationError &&
                            <div className="error-message">
                                <ErrorIcon />
                                <span>{authenticationError}</span>
                            </div>	
                        }
                    
                        <div className="buttons-wrapper">
                            <Button color="secondary" fullWidth={true} variant="contained" type="submit">
                                {labels.getLabel("enter", language)}
                            </Button>
                            <Button className="forgot-password" onClick={this.togglePasswordReset}>
                                {labels.getLabel("forgotPassword?", language)}
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default Login;
