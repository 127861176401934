import React, { Component } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';

export default class SearchSuggestions extends Component{
    renderSuggestion = (suggestion) => {
        return (
            <MenuItem
                key={suggestion.id}
                component="div"
                onClick={() => this.props.onSuggestionClick(suggestion)}
            >
                {suggestion.value}
            </MenuItem>
        );  
    }

    render(){
        const { 
            open, 
            anchorEl, 
            searchSuggestions,
            marginTop
        } = this.props;

        return(
            <Popper 
                open={open} 
                anchorEl={anchorEl}
                style={{
                    zIndex: 2000
                }}
            >
                <Paper
                    style={{ 
                        marginTop: (marginTop) ? marginTop : 8, 
                        width: (anchorEl) ? anchorEl.clientWidth : null
                    }}
                >
                    {searchSuggestions && searchSuggestions.map(suggestion => 
                        this.renderSuggestion(suggestion)
                    )}
                </Paper>
            </Popper>
        )
    }
}