import React, { Component } from 'react';
import ErrorIcon from '@material-ui/icons/Error';
import Section from './FormComponents/Section.js';
import Modal from './Modal.js';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Cookies from "js-cookie";
const labels = require("../labels.js");
const formFunctions = require("../formFunctions.js");
const utility_functions = require("../utility_functions.js");
const uuidv4 = require('uuid/v4');
/* global Stripe */

class DonationForm extends Component {
	initialState = {
		formErrors : {},
		generalErrorMessage : "",
		fixedAmountSelected : "",
		openAmountSelected : "",
		step : 1,
		formIncomplete : false,
		hasEmptyFields: false,
		formWasIncomplete : undefined,
		collectionCity: "",
		locationError : ""
	}

	constructor(props){
		super(props);

		this.state = {
			formData : {},
            submitData : {
				formValues : {}
			},
			validations : {},
			fieldLogic : [],
			requiredFields : [],
			prefixes : {},
			lockForm : false,
			...this.initialState
        }
	}

    componentWillMount(){
		this.getFormData();
		this.getCreditsLeft();
		this.checkCreditCardWarning();
	}

	checkCreditCardWarning = () => {
		const { language, setSnackBar } = this.props;
		const status = utility_functions.getURLParameterByName('status')
		if(status === "success"){
			setSnackBar(
				labels.getLabel("form_submission_successful", language), 
				"success"
			);
		}else if(status === "cancel"){
			setSnackBar(
				labels.getLabel("stripePaymentCancel", language), 
				"warning"
			);
		}
	}

	getFormData = () => {
		if( window.navigator.onLine){
			formFunctions.getForm()
			.then(formObj => {
				this.setForm(formObj);
			})
			.catch(error => {
				//console.log(error)
				/*this.setForm(
					JSON.parse(localStorage.getItem('formObj'))
				);*/
				
				/*const self = this;
				if(error.response && error.response.data.data 
					&& error.response.data.data.shortMessage && error.response.data.data.shortMessage === "NO_SESSION"){
					setTimeout(function(){ 
					  self.props.logout();
					}, 3000);
					this.props.setSnackBar(
					  labels.getLabel("doLoginAgain", this.props.language), 
					  "warning"
					);
				}*/
			});
		}
		else{
			this.setForm(
				JSON.parse(localStorage.getItem('formObj'))
			);
		}
	}

	getCreditsLeft = () => {
		utility_functions.getOrganizationData()
		.then(res => {
			const entriesBalance = res.data.data.entriesBalance;
			this.setState({ lockForm : entriesBalance <= 0 });
		})
		.catch(error => {
			const self = this;
			if(error.response && error.response.data.data 
				&& error.response.data.data.shortMessage && error.response.data.data.shortMessage === "NO_SESSION"){
				setTimeout(function(){ 
				  self.props.logout();
				}, 3000);
				this.props.setSnackBar(
				  labels.getLabel("doLoginAgain", this.props.language), 
				  "warning"
				);
			}
		});
	}

	setForm = (formObj) => {
		this.setState({
			formId : formObj.formId,
			formName : formObj.formName,
			formSections : formObj.formSections,
			fieldLogic : formObj.fieldLogic,
			validations : formObj.validations,
			requiredFields : formObj.requiredFields,
			prefixes : formObj.prefixes, 
			lastStep : formObj.lastStep,
			headerImage : formObj.headerImage,
			askEmptyFieldsAcknowledgement: formObj.askEmptyFieldsAcknowledgement,
			submitData : {
				...this.state.submitData,
				id: uuidv4(),
				formValues : formObj.initialFormValues
			},
			...this.initialState
		});
}
	getLocation = () => {
		navigator.geolocation.getCurrentPosition(
			(position) => {
				this.submitForm(position.coords)
			},
			(error) => {
				/*console.log(error);
				this.props.toggleLoading();
				this.setState({
					generalErrorMessage : labels.getLabel('geolocationError'+error.code, this.props.language, error.message)
				});*/
				setTimeout(
					() => {
						this.props.toggleLoading();
						this.setState({
							locationError: error.code+' - '+error.message,
						});
						this.submitForm();
					}
				, 100);
			},
			{ enableHighAccuracy: true, timeout: 30000 },
		);
	}

	getLocationPromise = () => 
		new Promise((resolve, reject) => {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					resolve(position.coords);
				},
				(error) => {
					this.setState({
						locationError: error.code+' - '+error.message,
					});
					reject({
						locationError: error.code+' - '+error.message,
					});
				},
				{ enableHighAccuracy: true, timeout: 30000 },
			);
		});

	handleSubmitClick = () => {
		const { language } = this.props;
		let { formIncomplete, formWasIncomplete, formErrors } = this.state;
		this.props.toggleLoading();

		let hasErrors = false;

		if(hasErrors){
			setTimeout(
				() => {
					this.props.toggleLoading();
					this.setState({ 
						formIncomplete : false,
						formErrors : formErrors
					});
				}
			, 100);
		}
		else{
			this.setState(
				{
					formErrors : {},
					generalErrorMessage : "",
					formIncomplete : false,
					formWasIncomplete : (formWasIncomplete !== undefined) ? formWasIncomplete : formIncomplete
				},
				() => {
					this.getLocationPromise()
					.then(result => {
						this.submitForm(result);
					})
					.catch(err => {
						if( err.locationError )
							this.setState(err,this.submitForm());
						else
							console.log(err);
					})
				}
			);
		}
	}

	submitForm = (locationCoordinates) => {
		const { language, userData, getLocalStorageEntries, setLocalStorageEntries, setLocalStorageFormEntry, checkLocalStorage, toggleLoading } = this.props;
		const { formId, locationError, submitData, prefixes } = this.state;
		let submitObj = {
			...submitData,
			date: Date.now().toString()
		}

		if(locationCoordinates)
			submitObj = {
				...submitObj,
				latitude : locationCoordinates.latitude.toString(),
				longitude : locationCoordinates.longitude.toString(),
				collectionLocationSource : "GPS"
			}
		else
			submitObj = {
				...submitObj,
				locationError : locationError
			}

		if(submitObj.formValues.paymentMethod){
			submitObj.formValues.paymentMethod = submitObj.formValues.paymentMethod.toLowerCase();
		}

		Object.keys(prefixes).forEach(p => {
			let value = submitObj.formValues[p];
			let prefixValue = prefixes[p];
			if(value === prefixValue)
				submitObj.formValues[p] = '';
		});

		let initialLocalStorageValue = getLocalStorageEntries(userData.orgId, userData.username).formEntries;
		setLocalStorageFormEntry(formId, {...submitObj, status : (this.state.formWasIncomplete) ? "Incomplete" : "Complete"});

		formFunctions.submitForm(formId, submitObj, false)
		.then(res => {
			const resData = res.data.data[0];
			
			setLocalStorageEntries(userData.orgId, userData.username, "formEntries", initialLocalStorageValue);
			checkLocalStorage();
			this.getFormData();
			this.getCreditsLeft();
			toggleLoading();

			if(resData.paymentMethod && resData.paymentMethod === "credit card"){
				const stripe = Stripe(resData.stripePubKey);
				stripe.redirectToCheckout({
					sessionId: resData.checkoutSessionId
				}).then((res) => {
					console.log(res);
				});
			}else{
				this.props.setSnackBar(
					labels.getLabel("form_submission_successful", language), 
					"success"
				);
			}
		})
		.catch(error => {
			if(error.response && error.response.data.data 
				&& error.response.data.data.shortMessage && error.response.data.data.shortMessage === "NO_BALANCE"){
				this.props.setSnackBar(
					labels.getLabel("form_submission_noBalance", language),
					"warning"
				);
				this.setState({ lockForm : true });
			} else if(submitObj.locationError && submitObj.locationError.indexOf('1') === 0) {
				this.props.setSnackBar(
					labels.getLabel("form_submission_activateLocation", language),
					"warning"
				);
			}else if(error.response && error.response.data.data 
				&& error.response.data.data.shortMessage && error.response.data.data.shortMessage === "NO_SESSION"){
				this.props.logout();
				this.props.setSnackBar(
					labels.getLabel("form_submission_successful", language), 
					"success"
				);
			}else if(!Cookies.get("f2fAppSID")){
				this.props.setSnackBar(
					labels.getLabel("form_submission_loginNeeded", language), 
					"warning"
				);
			}else {
				this.props.setSnackBar(
					labels.getLabel("form_submission_no_connection", language), 
					"warning"
				);
			}
			this.getFormData();
			this.getCreditsLeft();
			toggleLoading();
		});
	}
	
	setValue = (inputName, inputValue) => {
		const { submitData, openAmountSelected, fixedAmountSelected } = this.state;
		let { formErrors } = this.state;

		let auxInputName = (inputName === "open-amount" || inputName === "fixed-amount") ? "amount" : inputName;

		inputValue = (inputName === "postalCode")
		? formFunctions.getPostalCodeHiphen(inputValue, submitData.formValues.postalCode, submitData.formValues.country || 'Portugal')
		: inputValue;

		let formValues = submitData.formValues;
		formValues = {
			...formValues,
			[auxInputName] : inputValue
		};

		if(inputName === "signature" && formErrors.signature){
			formErrors = {
				...formErrors,
				signature : { valid:true, errorMessage:"" }
			}
		}

		const openAmountSelectedValue = (inputName === "open-amount") ? inputValue : (inputName === "fixed-amount") ? "" : openAmountSelected;
		const fixedAmountSelectedValue = (inputName === "fixed-amount") ? inputValue : (inputName === "open-amount") ? "" : fixedAmountSelected;

		this.setState({
			submitData : {...submitData, formValues},
			formErrors,
			openAmountSelected : openAmountSelectedValue,
			fixedAmountSelected : fixedAmountSelectedValue
		});
	}

	getInvalidFieldsInFormErrorsObj = (fieldLogic, step, formErrors) => {
		const fieldsWithErrors = Object.keys(formErrors).filter(field => formErrors[field] && formErrors[field].valid === false);
		let optionalFields = [];
		fieldLogic[step-1].forEach(field => {
			if(!(field instanceof Array) && field.charAt(0) === "?"){
				optionalFields.push(field.substring(1,field.length));
			}
		});
		if(fieldsWithErrors.length > 0){
			let auxFormErrors = {};
			fieldsWithErrors.forEach(field => {
				if(optionalFields.indexOf(field) === -1){
					auxFormErrors = {
						...auxFormErrors,
						[field] : formErrors[field]
					}
				}
			});
			return auxFormErrors;
		}
		return {};
	}

	getEmptyFields = (fieldData, step, fieldLogic) => {
		const formObj = JSON.parse(localStorage.getItem('formObj'));
		let emptyFields;
		if( fieldData && formObj ){
			let formSections = formObj.formSections;
			if( step != null )
				formSections = formSections.filter(section => section.step === step);

			const formFields = formSections.reduce((accumulator, section) => [...accumulator,...section.fields], []);
			emptyFields = formFields.map(field => {
				if( field.warnIfEmpty && !fieldData[field.name] && field.type != 'checkbox' )
					return field.label;
			}).filter(Boolean);
		}
		return emptyFields;
	}

	changeStep = ( value, newState ) => {
		const { language } = this.props;
		const { submitData, requiredFields, formErrors, validations, fieldLogic, step, lastStep, prefixes, askEmptyFieldsAcknowledgement, acknowledgedEmptyFields } = newState || this.state;
		const formValues = submitData.formValues;

		let errorMessages = {};
		let formIncomplete = false;
		let generalErrorMessages = "";
		let emptyFields = [];
		let hasErrors = {};

		if(value >= 0){
			const errorsInFormErrorObj = this.getInvalidFieldsInFormErrorsObj(fieldLogic, step, formErrors);
			const stepErrors = this.validateStep(step, fieldLogic, formValues, prefixes, requiredFields, validations, formErrors)
			errorMessages = {...errorsInFormErrorObj, ...stepErrors.errorMessages};
			formIncomplete = stepErrors.formIncomplete;
			generalErrorMessages = stepErrors.generalErrorMessages;
			
			Object.keys(errorMessages).forEach(e => {
				const value = errorMessages[e];
				if(!value.valid){
					hasErrors = {...hasErrors, [e] : value};
				}
			});

			if(askEmptyFieldsAcknowledgement) 
				emptyFields = this.getEmptyFields(formValues, step, fieldLogic);
		}

		if(Object.keys(hasErrors).length > 0 || generalErrorMessages !== ""){
			this.setState({
				generalErrorMessage : labels.getLabel("verifyFormFields" , language) + generalErrorMessages,
				formErrors : errorMessages
			});
		}
		else if( askEmptyFieldsAcknowledgement && emptyFields && emptyFields.length > 0 && !acknowledgedEmptyFields ){
			console.log(emptyFields);
			this.setState({
				hasEmptyFields : true
			});
		}
		else if(formIncomplete){
			this.setState({ 
				formIncomplete : true
			});
		}else{
			if(step === lastStep && value === 1){
				this.handleSubmitClick();
			}else{
				this.setState({
					generalErrorMessage : "",
					formErrors : errorMessages,
					step : step + value,
					formIncomplete : false
				});
				document.getElementById('form').scrollTop = 0;
			}
		}
	}

	getStepButtons = (step, lastStep) => {
		const { language } = this.props;
		let hasBackButton;
		 
		if(step > 1 && step <= lastStep && lastStep > 1)
			hasBackButton = <Button color="secondary" className="back-btn" variant="outlined" onClick={() => this.changeStep(-1)}>
								{labels.getLabel("back", language)}
							</Button> 

		return(
			<div className="buttons-wrapper">
				{hasBackButton}
				<Button color="primary" className="next-btn" variant="contained" onClick={() => this.changeStep(1)}>
					{(step < lastStep)
						? labels.getLabel("next", language)
						: labels.getLabel("donate", language)
					}
				</Button>
			</div>
		)
	}

	toggleModal = (modalName) => {
		this.setState({
			[modalName] : !this.state[modalName]
		});
	}

	isModalOpen = (modalName) => {
		return this.state[modalName] || false;
	}

	validateStep = (step, fieldLogic, fieldData, prefixes, requiredFields, validations) => {
		const { language } = this.props;
		let errorMessages = {};
		let generalErrorMessages = "";
		let formIncomplete = false;

		fieldLogic[step-1].forEach(field => {
			if (field instanceof Array) {
				let atLeastOne = false;
				field.forEach(fieldName => {
					if (fieldData[fieldName]) {
						atLeastOne = true;
						const required = requiredFields.indexOf(field) !== -1;
						const validationResult = this.validateField(fieldName, fieldData[fieldName], required, validations[fieldName], null);
						errorMessages = {
							...errorMessages,
							[fieldName] : validationResult
						};
					}
				});
				if (!atLeastOne) {
					let fieldsLabels = [];

					this.state.formSections.forEach(s =>
						s.fields.forEach(f => {
							if(field.indexOf(f.name) !== -1)
								fieldsLabels.push(f.label);
						})
					);

					generalErrorMessages += `<br/> ${ labels.getLabel("formAtLeastOneValueMandatory", language, "", fieldsLabels)}`;
				}
			} else {
				let belongsToFormIncomplete = (field.charAt(0) === "?" || field.charAt(0) === "#");
				const fieldName = (belongsToFormIncomplete)
					? field.substr(1, field.length)
					: field;
				const defaultValue = prefixes[fieldName];
				const fieldValue = fieldData[fieldName];
				
				if (!fieldValue || (defaultValue && fieldValue === defaultValue)) {
					if(belongsToFormIncomplete){
						formIncomplete = !fieldData["avoid"+fieldName];
					}else if(field === "amount"){
						generalErrorMessages += `<br/> ${ labels.getLabel("amountError", language)}`
					}else{
						errorMessages = {
							...errorMessages,
							[fieldName] : {valid:false, errorMessage:labels.getLabel("mandatoryField", language)}
						};
					}
				}
				else{
					const required = requiredFields.indexOf(field) !== -1;
					const auxFieldValue = (fieldName === "fiscalId")
						? this.state.submitData.formValues["fiscalId-country"]
						: null;
					const validationResult = this.validateField(fieldName, fieldValue, required, validations[fieldName], auxFieldValue);
					errorMessages = {
						...errorMessages, 
						[fieldName] : validationResult
					};
				}
			}
		});

		return { errorMessages, formIncomplete, generalErrorMessages };
	}

	validateField = (fieldName, fieldValue, required, fieldValidations, auxFieldValue) => {
		const validationObj = formFunctions.validateField(fieldName, fieldValue, required, fieldValidations, auxFieldValue, this.props.language);

		const formErrors = {
			...this.state.formErrors, 
			...validationObj.formErrors
		}

		const hasErrors = Object.values(formErrors)
			.filter(error => error && error.valid === false)
			.length > 0;
		const generalErrorMessage = (hasErrors) ? this.state.generalErrorMessage : "";

		this.setState({
			formErrors : formErrors,
			generalErrorMessage : generalErrorMessage
		});

		return validationObj.validationResult;
	}

    render(){
		const { 
			formName,
			formSections,
			submitData,
			step,
			lastStep,
			fixedAmountSelected,
			openAmountSelected,
			generalErrorMessage,
			formErrors,
			lockForm,
			headerImage,
			fieldLogic
		} = this.state;

		const {
			themeColors,
			isMobile,
			language
		} = this.props;

		const amountSelected = submitData.formValues.amount;
		const headerStep2Style = {
			backgroundColor: themeColors.primary.main,
			color: themeColors.primary.contrastText
		}

		return(
			<div className="form" id="form">
				{!lockForm ?
					<div>
						{(step === 1 &&
							<div className="header step1">
								<div
									className="image"
									style={{ background: `url(${headerImage})` }}
								/>
								<div className="title-wrapper">
									<div className="background" style={{backgroundColor: themeColors.secondary.main}} />
									<p className="title" style={{color: themeColors.secondary.contrastText}}>
										{formName}
									</p>
								</div>
							</div>)
						|| (step > 1 &&
							<div className="header" style={headerStep2Style}>
								<p className="title">
									{labels.getLabel("monthlyDonation", language)} {amountSelected}€
								</p>
							</div>)
						}
						<div className="content">
							<div className="mandatoryFields-hint">
								{labels.getLabel("form_mandatoryFields", language)}
							</div>

							{formSections && formSections.map(section => {
								if(section.step === step){
									return (
										<Section
											key={section.name}
											sectionData={section}
											submitData={submitData} 
											setValue={this.setValue}
											formErrors={formErrors}
											validateField={this.validateField}
											themeColors={themeColors} 
											toggleModal={this.toggleModal}
											isModalOpen={this.isModalOpen}
											getSignature={this.getSignature}
											fixedAmountSelected={fixedAmountSelected}
											openAmountSelected={openAmountSelected}
											isMobile={isMobile}
											language={language}
											fieldLogic={fieldLogic}
											step={step}
										/>
									)
								}else{
									return null;
								}
							})}

							{generalErrorMessage &&
								<div className="error-message">
									<ErrorIcon />
									<div dangerouslySetInnerHTML={{__html: generalErrorMessage}} />
								</div>	
							}

							<Modal
								open={this.isModalOpen("hasEmptyFields") && !this.state.acknowledgedEmptyFields}
								title={labels.getLabel("sureToContinue", language)}
								text={labels.getLabel("thereAreEmptyFields", language)}
								primaryButtonText={labels.getLabel("yes", language)}
								primaryAction={() =>{
									this.setState({
										acknowledgedEmptyFields : true
									});
									this.changeStep(0, {...this.state, acknowledgedEmptyFields : true});
									this.toggleModal("hasEmptyFields")
								}}
								secondaryButtonText={labels.getLabel("no", language)}
								secondaryAction={() => this.toggleModal("hasEmptyFields")}
							/>

							<Modal
								open={this.isModalOpen("formIncomplete")}
								title={labels.getLabel("sureToContinue", language)}
								text={labels.getLabel("formWillBeIncomplete", language)}
								primaryButtonText={labels.getLabel("yes", language)}
								primaryAction={
									(step === lastStep)
										? this.handleSubmitClick
										: () => this.changeStep(1)
								}
								secondaryButtonText={labels.getLabel("no", language)}
								secondaryAction={() => {
									this.setState({
										acknowledgedEmptyFields : false
									});
									this.toggleModal("formIncomplete");
								}}
							/>

							{this.getStepButtons(step, lastStep)}                
						</div>
					</div>
				:
					<div style={{
						marginTop: "25px",
						color: "orangered"
					}}>
						{labels.getLabel("noLabel", language)}
					</div>
				}
			</div>
        )
    }
}

export default DonationForm;

