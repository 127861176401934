import React, { Component } from 'react';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockClosedIcon from '@material-ui/icons/Lock';
import 'jsignature/libs/jquery.js';
import 'jsignature/libs/flashcanvas.js';
import 'jsignature/libs/jSignature.min.js';
const labels = require("../../labels");

export default class Signature extends Component {
	constructor(props){
		super(props);
		this.state = {
			readOnly : true //this.props.readOnly || false
		}
		this.signature = React.createRef();
	}

	toggleSignatureLock = () => {
		this.$signature = window.$(this.signature.current);
		if(!this.state.readOnly === false)
			this.$signature.jSignature("enable");
		else
			this.$signature.jSignature("disable");
		this.setState({ readOnly: !this.state.readOnly });
	}
	
	getSignature = () => {
        const imageObject = window.$("#signature").jSignature("getData", "base30");
		//console.log(imageObject)

		if(imageObject && imageObject[1])
			this.props.setValue('signature', imageObject.join(','), false);
		else
			this.props.setValue('signature', '', false);
    }

	componentDidMount(){
		var self = this;	
		const readOnly = true; //this.props.readOnly || true;
		this.$signature = window.$(this.signature.current);
 
		this.$signature.jSignature({
			lineWidth: 2,
			readOnly: readOnly,
			height: 300,
			width: "100%"
		});

		//console.log(this.$signature)
		//console.log(window.$("#signature").jSignature("getSettings"));

		this.$signature.bind('change', function(e){ 
			/* 'e.target' will refer to div with "#signature" */
			self.props.setValue && self.getSignature(); 
		});

		const existingSignature = this.props.existingSignature;
		readOnly && existingSignature && existingSignature.indexOf("image/jsignature;base30") === 0 && this.$signature.jSignature(
			"setData", "data:"+existingSignature
		);;
	}

	clearSignature = () => {
		this.$signature = window.$(this.signature.current);
        this.$signature.jSignature("clear");
	}
	
	render(){
		const { hasError, errorMessage, language } = this.props;
		const { readOnly } = this.state;

		return(
			<div className="signature-wrapper">
				{!readOnly && 
					<button className="delete-button" onClick={this.clearSignature}>
						{labels.getLabel("clearSignature", language)}
					</button>
				}
				{(this.props.readOnly === undefined || (!this.props.readOnly && !readOnly)) && 
					<IconButton
						aria-label="Lock Signature"
						className="lock-button"
						color="inherit"
						onClick={this.toggleSignatureLock}
					>
						{readOnly 
							? <LockClosedIcon />
							: <LockOpenIcon />
						}
					</IconButton>
				}
				<div id="signature" ref={this.signature} />
				{hasError && 
					<FormHelperText error={true}>{errorMessage}</FormHelperText>	
				}
			</div>	
		)
	}
}