import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

export default class Picklist extends Component{
	render(){
		const {fieldData, fieldValue, inputWrapperStyle, hasError, errorMessage, setValue, validateField, readOnly} = this.props;

		return (
			<TextField
				id={fieldData.name}
				error={hasError}
				helperText={errorMessage}
				fullWidth={true}
				className={(fieldValue) ? "input-wrapper filled" : "input-wrapper"}
				style={ inputWrapperStyle(fieldData.width) }
				select
				label={fieldData.label + (fieldData.required ? " *":"")}
				name={fieldData.name}
				value={fieldValue || ""}
				onChange={(e) => setValue(fieldData.name, e.target.value)}
				variant="outlined"
				onBlur={(e) => !readOnly && validateField(fieldData.name, e.target.value, fieldData.required, fieldData.validations)}
				readOnly={readOnly}
			>
				{fieldData.picklistValues.map((e, index) => {
					return (
						<MenuItem key={index} value={e.value}>{e.label}</MenuItem>
					)
				})}
			</TextField>
		)
	}
} 