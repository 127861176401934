import validations from "./validations.js";
import axios from 'axios';
const host = (process.env.NODE_ENV === "development") ? require("./config.json").developerHost : require("./config.json").productionHost;
const labels = require("./labels");

export function validateField (fieldName, fieldValue, required, fieldValidations, auxFieldValue, language) {
    let allValidationsResult = [];

    if( fieldValue || (!fieldValue && required)){
        fieldValidations && fieldValidations.forEach(fieldValidation => {
            console.log(fieldValidation);
            if(fieldValidation.type === 'function'){
                const functionName = fieldValidation.value;
                const param = fieldValidation.params || auxFieldValue;
                let validationResult = validations[functionName](fieldValue, fieldValidation.errorMessage, param, fieldName === "mobile");
                console.log(validationResult);
                validationResult.errorMessage = labels.getLabel(validationResult.errorMessage, language, validationResult.errorMessage, param);
                allValidationsResult.push(validationResult);
            }else if(fieldValidation.type === 'regex'){
                let validationResult = validations.regex(fieldValue, fieldValidation.value, fieldValidation.errorMessage);
                console.log(validationResult);
                validationResult.errorMessage = labels.getLabel(validationResult.errorMessage, language, validationResult.errorMessage);
                allValidationsResult.push(validationResult);
            }
        });
    }

    let errorMessages = "";
    allValidationsResult.forEach(validationResult => {
        errorMessages = errorMessages+' '+validationResult.errorMessage;
    });

    const formErrors = {
        [fieldName] : {
            valid : (allValidationsResult.filter(validationResult => validationResult.valid === false).length > 0) ? false : true,
            errorMessage : errorMessages
        }
    }

    return {
        validationResult : formErrors[fieldName],
        formErrors : formErrors
    };
}

export function getForm (formId) {
    let url = (formId) ? `${host}/forms/${formId}` : `${host}/forms`;

    return new Promise((resolve, reject) => {
        axios.get(url, {withCredentials:true, headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'}})
        .then(res => {
            if(res.data.status === "success") 
                resolve( handleFormData(res.data.data) );   
        })
        .catch(error => {
            reject(error);
        });
    })
}

export function submitForm (formId, submitData, isOnline) {
    const url = `${host}/forms/${formId}/entries${isOnline ? "/online" : ""}`;
    return new Promise((resolve, reject) => {
        axios.put(url, [submitData], {withCredentials:true})
		.then(res => {
            if(res.status >= 200 && res.status < 300) 
                resolve(res);
		})
		.catch(error => reject(error));
    })
}

export function getPostalCodeHiphen (newValue, currentValue, country) {
    let pattern = /^[0-9]{4}$/;
    let patternDash = /^[0-9]{4}-$/;
  
    if( newValue > currentValue && country.toLowerCase() === 'portugal' && pattern.test(newValue) && !patternDash.test(newValue) ) 
        return newValue+'-';
    else
        return newValue;
}


const handleFormData = (formData) => {
    //console.log(formData);

    let validations = {};
    let requiredFields = [];
    let initialFormValues = {};
    let lastStep = 1;	
    let formId = formData.id;
    let formName = formData.attributes.name;
    let { formSections, headerImage, fieldLogic, askEmptyFieldsAcknowledgement } = formData.attributes.formSchema
    let prefixes = {};

    formSections.forEach(section => {
        if(lastStep < section.step) 
            lastStep = section.step;

        section.fields.forEach(field => {
            initialFormValues = {
                ...initialFormValues,
                [field.name] : (field.type === "checkbox") ? false : (field.defaultValue) ? field.defaultValue : (field.prefix) ? field.prefix : ""
            }
            
            if(field.required)
                requiredFields.push(field.name);

            if(field.prefix)
                prefixes = {
                    ...prefixes, 
                    [field.name] : field.prefix
                };

            if(field.validations)	
                validations = {
                    ...validations,
                    [field.name] : field.validations
                }
        })
    });

    return {
        formId,
        formName,
        formSections,
        askEmptyFieldsAcknowledgement,
        fieldLogic : parseFieldLogic(fieldLogic),
        validations,
        requiredFields,
        prefixes,
        lastStep,
        initialFormValues,
        headerImage
    };
}

const parseFieldLogic = fieldLogic => {
    let fieldLogicArray = fieldLogic.split(" ; ");
    fieldLogicArray.forEach((element, index1) => {
        fieldLogicArray[index1] = element.split(" AND ");
        fieldLogicArray[index1].forEach((element, index2) => {
            if (element.indexOf("OR") !== -1) {
                fieldLogicArray[index1][index2] = element
                .substring(1, element.length - 1)
                .split(" OR ");
            }
          });
    });

    return fieldLogicArray;
};