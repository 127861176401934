import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import DialogContentText from '@material-ui/core/DialogContentText';

class Modal extends Component{
	render(){
		const { 
			className, 
			title, 
			text, 
			primaryAction, 
			secondaryAction, 
			open, 
			primaryButtonText, 
			secondaryButtonText, 
			fullScreen,
			content
		} = this.props;

		return(
			<Dialog 
				className={className+' modal'}
				open={open}
				fullScreen={fullScreen}
				fullWidth
			>
				<div>
					<DialogTitle>
						{title}
					</DialogTitle>
					<DialogContent>
						<DialogContentText dangerouslySetInnerHTML={{ __html: text }} />
						{content}
					</DialogContent>
					<DialogActions className="modal-buttons-wrapper">
						{ secondaryButtonText &&
							<Button 
								onClick={secondaryAction} 
								variant="outlined"
							>
								{secondaryButtonText}
							</Button>
						}
						{ primaryButtonText &&
							<Button 
								onClick={primaryAction}
								color="primary"
                    			variant="contained"
							>
								{primaryButtonText}
							</Button>
						}
					</DialogActions>
				</div>
			</Dialog>
		)
	}
}

export default withMobileDialog()(Modal);